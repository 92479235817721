import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/common/layout"
import Meta from "../../components/common/meta"
import PageTitle from "../../components/pageTitle/pageTitle"
import ShareIcon from "../../components/share/share"
import BodyContents from "../../components/pages/newsArticle/BodyContents"

const PagerPrev = props => {
  return (
    <Link
      to={`/news/${props.slug}`}
      className="pager-min__arrow pager-min__arrow--prev"
    >
      <span className="pager-min__arrow-t">prev</span>
    </Link>
  )
}
const PagerNext = props => {
  return (
    <Link
      to={`/news/${props.slug}`}
      className="pager-min__arrow pager-min__arrow--next"
    >
      <span className="pager-min__arrow-t">next</span>
    </Link>
  )
}

const NewsDetail = ({ data, pageContext }) => {
  const check = data.prismic.allNewsarticles.edges.slice(0, 1).pop()
  if (!check) return null
  const context = data.prismic.allNewsarticles.edges[0].node
  const date = context.date.split("-").map(x => Number(x))
  const metaTitle = context.title_ja[0].text
  const metaUrl = "https://www.1moku.co.jp/news/" + pageContext.uid
  const isNextPage = pageContext.paginationNextUid.length ? true : false
  const isPrevPage = pageContext.paginationPreviousUid.length ? true : false

  return (
    <Layout page="news-single">
      <Meta
        title={metaTitle}
        description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.の最新情報をご紹介します。"
        url={metaUrl}
        type="article"
      />
      <PageTitle title="news" />
      <div className="news-main news-main--article">
        <article className="article">
          <div className="article__head article-head">
            <h1 className="article-head__in" data-scroll="">
              <span className="article-head__title en">
                {context.title_en[0].text}
              </span>
              <span className="article-head__title ja">
                {context.title_ja[0].text}
              </span>
            </h1>
            <div className="article-head__info" data-scroll="">
              <span className="article-head__info-date">{date.join(".")}</span>
              <span className="article-head__info-category">
                {context.category}
              </span>
            </div>
            <ShareIcon shareClass="article-head__share pc" pageUrl={metaUrl} />
          </div>
          <div className="article__body article-body">
            <BodyContents contents={context.body} />
            <ShareIcon shareClass="article-body__share sp" pageUrl={metaUrl} />
            <div className="article-body__bottom">
              <div className="back">
                <Link to="/news" className="back__link">
                  back to all news
                </Link>
              </div>
              <div className="article-body__pager">
                <div className="pager-min">
                  {isPrevPage && (
                    <PagerPrev slug={pageContext.paginationPreviousUid} />
                  )}
                  {isNextPage && (
                    <PagerNext slug={pageContext.paginationNextUid} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query newsArticleQuery($uid: String) {
    prismic {
      allNewsarticles(uid: $uid) {
        edges {
          node {
            body {
              ... on PRISMIC_NewsarticleBody__ {
                type
                label
                primary {
                  img
                }
              }
              ... on PRISMIC_NewsarticleBody_______ {
                type
                label
                primary {
                  text_ja
                }
              }
              ... on PRISMIC_NewsarticleBody______ {
                type
                label
                primary {
                  text_en
                }
              }
            }
            category
            date
            title_en
            title_ja
          }
        }
      }
    }
  }
`

export default NewsDetail
