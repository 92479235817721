import React from "react"

const BodyContents = props => {
  const contetns = props.contents
  const bodyContetns = []
  contetns.forEach((v, i) => {
    const type = Object.keys(v.primary).shift()
    switch (type) {
      case "img": {
        bodyContetns.push(
          <div key={i} className="article-body__visual" data-scroll="">
            <img src={v.primary.img.url} alt="" />
          </div>
        )
        break
      }
      case "text_ja": {
        const text = []
        v.primary.text_ja.forEach((v, i) => {
          const insertText = i === 0 ? v.text : "<br />" + v.text
          text.push(insertText)
        })
        bodyContetns.push(
          <p
            key={i}
            className="article-body__text-t ja"
            data-scroll=""
            dangerouslySetInnerHTML={{ __html: text.join("") }}
          />
        )
        break
      }
      case "text_en": {
        const text = []
        v.primary.text_en.forEach((v, i) => {
          const insertText = i === 0 ? v.text : "<br />" + v.text
          text.push(insertText)
        })
        bodyContetns.push(
          <p
            key={i}
            className="article-body__text-t en"
            data-scroll=""
            dangerouslySetInnerHTML={{ __html: text.join("") }}
          />
        )
        break
      }
      default:
        break
    }
  })

  return bodyContetns
}

export default BodyContents
